import React, { useState } from 'react';
import "./Home.scss";
import keys from "./keys.png";
import { midiDB } from "/Users/calvinlloyd/Documents/chordgen-website/main-page/src/firebase-config.js";
import { v4 } from "uuid";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { DB} from "/Users/calvinlloyd/Documents/chordgen-website/main-page/src/firebase-config.js";
import { doc, setDoc } from "firebase/firestore";


async function addDat(uname,id,link,key,chord){
    await setDoc(doc(DB, uname, id), {
        "id":id,
      "link":link,
      "key":key,
      "chord progression":chord
    });
} 



const MidiWriter = require('midi-writer-js');
function getIntList(){
    let keys = ['F4','G4','A4','B4','C5', 'D5', 'E5', 'F5', 'G5','A5', 'B5','C6','D6'];
    let initialSequence = [];
    let tempKeys = [];
    let tempList = [];
    let chord_to_int = {};
    for (let i = 0; i < keys.length; i++) {
        chord_to_int[keys[i]] = i;
    }
    for (let i = 0; i < keys.length; i++) {
        tempKeys.push(chord_to_int[keys[i]]);
    }
    for (let i = 0; i < 3; i++) {
        let randomIndex = Math.floor(Math.random() * tempKeys.length);
        tempList.push(tempKeys[randomIndex]);
    }
    tempList.forEach(chordIndex => {
        initialSequence.push(chordIndex);
    });
    let chordProgression = initialSequence.slice();
    let inputArray = [chordProgression.slice(-3)];
    return inputArray;
}
async function predict(dat,choice) {
    var predictedVal;
    var req_list = dat.slice(-3);
    let link = 'https://us-central1-chordgen-5da02.cloudfunctions.net/ModelApi?data="['+req_list+'],'+choice+'"';
    try {
        const response = await fetch(link);
        const data = await response.json();
        predictedVal = data;
        console.log("list ="+dat+" predit ="+predictedVal);
        return predictedVal;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}
function keysToCMidiNotes(keys,num) {
    // eslint-disable-next-line
    if (num == 1){
        console.log("num ="+num);
        const noteNumbers = {
            12 : 45,//12 B5
            11 : 47,//11
            10 : 48,//10
            9 : 50,//9
            8 : 52,//8
            7 : 53,//7
            6 : 55,//6
            5 : 57,//5
            4 : 59,//4
            3 : 60,//3
            2 : 62,//2
            1 : 64,//1 
            0 : 65 //0 B4
        };
        return keys.map(key => noteNumbers[key]);
    }
    else{
        // eslint-disable-next-line
        if(num == 2){
            console.log("num ="+num);
            const noteNumbers = {
                12 : 43,//12 G3
                11 : 45,//11
                10 : 47,//10
                9 : 49,//9
                8 : 50,//8
                7 : 52,//7
                6 : 54,//6
                5 : 55,//5
                4 : 57,//4
                3 : 59,//3
                2 : 61,//2
                1 : 62,//1 
                0 : 64 //0 E5
            };
            return keys.map(key => noteNumbers[key]);
        }
        else{
             // eslint-disable-next-line
            if (num == 3){
                console.log("num ="+num);
                const noteNumbers = {
                    12 : 44,//12 G#3
                    11 : 45,//11
                    10 : 47,//10
                    9 : 49,//9
                    8 : 51,//8
                    7 : 52,//7
                    6 : 54,//6
                    5 : 56,//5
                    4 : 57,//4
                    3 : 59,//3
                    2 : 61,//2
                    1 : 63,//1 
                    0 : 64 //0 E5
                };
                return keys.map(key => noteNumbers[key]);
            }
            else{
                // eslint-disable-next-line
                if (num == 4){
                    console.log("num ="+num);
                    const noteNumbers = {
                        12 : 43,//12 G3
                        11 : 45,//11
                        10 : 46,//10
                        9 : 48,//9
                        8 : 50,//8
                        7 : 52,//7
                        6 : 53,//6
                        5 : 55,//5
                        4 : 57,//4
                        3 : 58,//3
                        2 : 60,//2
                        1 : 62,//1 
                        0 : 64 //0 E5
                    };
                    return keys.map(key => noteNumbers[key]);
                }
                else{
                // eslint-disable-next-line
                if (num == 5){
                    console.log("num ="+num);
                    const noteNumbers = {
                        12 : 43,//12 G3
                        11 : 45,//11
                        10 : 47,//10
                        9 : 48,//9
                        8 : 50,//8
                        7 : 52,//7
                        6 : 54,//6
                        5 : 55,//5
                        4 : 57,//4
                        3 : 59,//3
                        2 : 60,//2
                        1 : 62,//1 
                        0 : 64 //0 E5
                    };
                    return keys.map(key => noteNumbers[key]);
                }
                else{
                                    // eslint-disable-next-line
                if (num == 6){
                    console.log("num ="+num);
                    const noteNumbers = {
                        12 : 44,//12 G3
                        11 : 45,//11
                        10 : 47,//10
                        9 : 49,//9
                        8 : 50,//8
                        7 : 52,//7
                        6 : 54,//6
                        5 : 56,//5
                        4 : 57,//4
                        3 : 59,//3
                        2 : 61,//2
                        1 : 62,//1 
                        0 : 64 //0 E5
                    };
                    return keys.map(key => noteNumbers[key]);
                }
                else{
                                    // eslint-disable-next-line
                if (num == 7){
                    console.log("num ="+num);
                    const noteNumbers = {
                        12 : 44,//12 G3
                        11 : 46,//11
                        10 : 47,//10
                        9 : 49,//9
                        8 : 51,//8
                        7 : 52,//7
                        6 : 54,//6
                        5 : 56,//5
                        4 : 58,//4
                        3 : 59,//3
                        2 : 61,//2
                        1 : 63,//1 
                        0 : 64 //0 E5
                    };
                    return keys.map(key => noteNumbers[key]);
                }
                else{
                    console.log("error input")
                }
                }
                }
                }
            }
        }
    }
}
async function createMidiFromKeys(keys,tempo,numm = 1) {
    keys = keys.slice(3);
    const notes = await keysToCMidiNotes(keys,numm);
    console.log("keys =",notes);
    const track = new MidiWriter.Track();
    for (let i = 0; i < notes.length; i += 3) {
        const chordNotes = notes.slice(i, i + 3);
        track.addEvent(new MidiWriter.NoteEvent({ pitch: chordNotes, duration: '1', wait: 0, velocity:100 }));
        track.setTempo(tempo);
    }
    const writer = new MidiWriter.Writer([track]);
    const midiBlob = new Blob([writer.buildFile()], { type: 'audio/midi' });
    return midiBlob;
}
async function getlink(id) {
    try {
        const storage = midiDB;
        const fileRef = ref(storage, `/ouput/${id}.mid`);
        const url = await getDownloadURL(fileRef);
        return url;
    } catch (error) {
        console.error('Error getting download URL:', error);
        return null;
    }
}
//diyas fav color green


export const Home = () => {
    window.onload = function() {
    document.getElementById("numberInput").value = "120";
    };
    var accuracy = 'Good';
    const[accu,setAction] = useState(accuracy);
    const [selectedValue, setSelectedValue] = useState(1);
    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };
    const [selectedValue2, setSelectedValue2] = useState(4);
    const handleChange2 = (event) => {
      setSelectedValue2(parseInt(event.target.value));
    };
    const[keyy,keyUpdate] = useState('C');
    var val = selectedValue;
    var [selectedValue3, setSelectedValue3] = useState(120);

    const handleChange3 = (event) => {
      setSelectedValue3(parseInt(event.target.value));
    };
    //setSelectedValue3(tempo);
    async function listMaker() {
        const element2 = document.getElementsByClassName('load_bucket')[0];
        element2.style.visibility = 'visible';
        const element3 = document.getElementsByClassName('button_div')[0];
        element3.style.visibility = 'hidden';
        const element9 = document.getElementsByClassName("keys")[0];
        element9.style.visibility = 'visible';
        let init_list = getIntList();
        var flat = init_list.flat();
        //var flat = []
        var counter = (3*(selectedValue2));
        for (let i = 0; i < counter; i++) {
            var temp = await predict(flat,val);
            //var temp = await predict(getIntList(),val);
            var flag = 1;
            var list = [0,1,2,3,4,5,6,7,8,9,10,11,12];
            while (flag === 1){
                if (flat[flat.length - 1] === temp){
                    //eslint-disable-next-line
                    list = list.filter(num => num !== temp);
                    let temp_temp = list[Math.floor(Math.random() * list.length)];
                    flat.push(temp_temp);
                    accuracy = 'Bad';
                    console.log("rand =",temp_temp);
                    break;
                }
                else{
                    if (flat[flat.length - 2] === temp){
                        // eslint-disable-next-line
                        list = list.filter(num => num !== temp);
                        let temp_temp = list[Math.floor(Math.random() * list.length)];
                        flat.push(temp_temp);
                        accuracy = 'Bad';
                        console.log("rand =",temp_temp);
                        break;
                    }
                    else{
                        flat.push(temp);
                        break;
                    }
                }
            }
            
        }
        // eslint-disable-next-line
        if (val == 1){
            keyUpdate("C");
        }
        else{
            // eslint-disable-next-line
            if( val == 2){
                keyUpdate("D");
            }
            else{
                // eslint-disable-next-line
                if( val == 3){
                    keyUpdate("E");
                }
                else{
                    // eslint-disable-next-line
                    if( val == 4){
                        keyUpdate("F");
                    }
                    else{
                        // eslint-disable-next-line
                        if( val == 5){
                            keyUpdate("G");
                        }
                        else{
                            // eslint-disable-next-line
                            if( val == 6){
                                keyUpdate("A");
                            }
                            else{
                                // eslint-disable-next-line
                                if( val == 7){
                                    keyUpdate("B");

                                }
                                else{
                                    console.log("error on val"+val);
                                }
                            }
                        }
                    }
                }
            }
        }
        let keys = flat;
        console.log(keys);
        // console.log(selectedValue3);
        let midi = await createMidiFromKeys(keys,selectedValue3,val);
        var id = v4();
        const midiRef = ref(midiDB, `ouput/${id}.mid`);
        await uploadBytes(midiRef, midi);
        const link = await getlink(id);
        const img = document.getElementById ('mainVisualizer');
        img.setAttribute('src', link);
        const img2 = document.getElementById ('mainPlayer');
        img2.setAttribute('src', link);
        const element4 = document.getElementsByClassName('load_bucket')[0];
        element4.style.visibility = 'hidden';
        const element6 = document.getElementsByClassName('button_div')[0];
        element6.style.visibility = 'visible';
        const element7 = document.getElementsByClassName("info_cont")[0];
        element7.style.display = "flex";
        const element8 = document.getElementsByClassName("down")[0];
        element8.setAttribute('href',link);
        const element10 = document.getElementsByClassName("keys")[0];
        element10.style.visibility = 'hidden';
        setAction(accuracy);
        accuracy = 'Good';
        const cords = document.getElementById("select-catog2").value
        const state = window.localStorage.getItem("LoginState");
        if(state){
            const uname = window.localStorage.getItem("email");       
            await addDat(uname,id,link,keyy,cords);
            console.log("saved");
        }
        else{
            console.log(state);
        }
        window.onload = function() {
            document.getElementById("numberInput").value = "120";
        };

    }
    return (
        <div>
            <div className="button_n_anime">
                <div className="button_div">
                    <div className='select-container1'>
                        <label className='select-label' for='select-cat'>
                            Scale:
                        </label>
                        <div id='select-catog-container'>
                            <select name='listt' id='select_cat' value={selectedValue} onChange={handleChange}>
                                <option value="1">
                                    C
                                </option>
                                <option value="2">
                                    D
                                </option>
                                <option value="3">
                                    E
                                </option>
                                <option value="4">
                                    F
                                </option>
                                <option value="5">
                                    G
                                </option>
                                <option value="6">
                                    A
                                </option>
                                <option value="7">
                                    B
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className='select-container2'>
                        <div>
                            <label className='select-label' for='select-catog3'>
                                Progressions:
                            </label>
                        </div>
                        <div id='select-catog-container'>
                            <select name='listt' id='select-catog2' value={selectedValue2} onChange={handleChange2}>
                                <option value="4">
                                    4
                                </option>
                                <option value="8">
                                    8
                                </option>
                            </select>

                        </div>
                    </div>
                    <div className='tempo_div'>
                    <label for="numberInput">Tempo:</label>
                    <input type="number" id="numberInput" name="numberInput" min="1" value={selectedValue3} onChange={handleChange3} required></input>
                    </div>
                    <button className="gen_but" onClick={listMaker} >
                        GENERATE
                    </button>
                </div>
                <div className="keys">
                <img src={keys} alt="Logo" height="80px"/>
                </div>
                <div className="load_bucket">
                    <div className="containere">
                        <div class="📦"></div>
                        <div class="📦"></div>
                        <div class="📦"></div>
                        <div class="📦"></div>
                        <div class="📦"></div>
                    </div>
                </div>
            </div>
            <div className="subout">
                <div className="player_vis">
                <midi-visualizer type="piano-roll" id="mainVisualizer" src= ""></midi-visualizer>
                <midi-player src= "" sound-font="" visualizer="#mainVisualizer" id="mainPlayer" data-js-focus-visible>
                </midi-player>
                </div>
            </div>
            <div className="info_cont">
                <div className="info_box">
                    <p>Accuracy = {accu}</p>
                    <p className="info_box_text1">Key-Scale : {keyy}</p>
                    <p>Tempo : {selectedValue3}bpm</p>
                    <a className="down" href="/" >DOWNLOAD</a>
                </div>
            </div>
        </div>
    );
}