import React from "react";
import "./NavBar.css";
import { Link, NavLink } from "react-router-dom";
import main_logo from "./chordgen_logo.png";
import menu_logo from "./menu_new.png"
//import { doc } from "firebase/firestore";

function runMenu(){
    // const element2 = document.getElementsByClassName('mod_div')[0];
    // element2.style.display = 'list-item';
    // const element3 = document.getElementsByClassName('top_line')[0];
    // element3.style.transform = 'rotate(40deg)';
    // element3.style.transform = 'translateY(5px)';
    // const element4 = document.getElementsByClassName('down_line')[0];
    // element4.style.transform = 'rotate(-40deg)';
    // element4.style.transform = 'translateY(-5px)';
    var element = document.getElementById("line");
    if (element.classList.contains("top_line")) {
        // If the element has the original class, remove it and add the new class
        element.classList.remove("top_line");
        element.classList.add("top_line_next");
        // const element2 = document.getElementsById("mod_div");
        // element2.classList.remove("menu_before")
        // element2.classList.add("menu_after");
    } else {
        // If the element has the changed class, remove it and add the original class
        element.classList.remove("top_line_next");
        element.classList.add("top_line");
    }
    var element2 = document.getElementById("line2");
    if (element2.classList.contains("down_line")) {
        // If the element has the original class, remove it and add the new class
        element2.classList.remove("down_line");
        element2.classList.add("down_line_next");
    } else {
        // If the element has the changed class, remove it and add the original class
        element2.classList.remove("down_line_next");
        element2.classList.add("down_line");
    }
    var element3 = document.getElementById("mod_div");
    if (element3.classList.contains("menu_before")) {
        // If the element has the original class, remove it and add the new class
        element3.classList.remove("menu_before");
        element3.classList.add("menu_after");
        // const element2 = document.getElementsById("mod_div");
        // element2.classList.remove("menu_before")
        // element2.classList.add("menu_after");
    } else {
        // If the element has the changed class, remove it and add the original class
        element3.classList.remove("menu_after");
        element3.classList.add("menu_before");
    }
}

// function runVal(){
//     document.getElementById("numberInput").value = "120";
// }

export const NavBar = () => {
    if (window.localStorage.getItem("navState") === null){
        window.localStorage.setItem("navState", "LOGIN/REGISTER");
    }
    const loginState = window.localStorage.getItem("navState");
    return (
    <div>
        <div className="nav_bar_css">
            <div class = "logo_placement">
                <Link to= "/"  onClick={runMenu}><img src= {main_logo} alt="logo" height= "20px"/></Link>
            </div>
            <div class = 'nav'>
                <div className="nav_list_div">
                    <ul className = "nav_list">
                        <li id="link_active">
                            <NavLink to = "/" activeClassName="active">HOME</NavLink>
                        </li>
                        <li id="link_active">
                            <NavLink to = "about" activeClassName="active">ABOUT</NavLink>
                        </li>
                        <li id="link_active">
                            <NavLink to = "profile" activeClassName="active" id="prifileSwitch">{loginState}</NavLink>
                        </li>
                        
                    </ul>
                </div>
                    <div id="menu_div">
                        <div className="menu">
                            <Link onClick={runMenu}>
                            <img id="line" className="top_line" src={menu_logo} alt= 'menu_logo' height='6px' />
                            <img id="line2" className="down_line" src={menu_logo} alt= 'menu_logo' height='6px' />
                            </Link>
                        </div>
                    </div>
             </div>
         </div>
         <div id="mod_div" className="menu_before"> 
            <ul className="mod_menu">
                <li id="link_active">
                    <NavLink to = "/" activeClassName="active"  onClick={() => {
                    // runVal();
                  runMenu();
                }}>HOME</NavLink>
                </li>
                <li id="link_active">
                    <NavLink to = "about" activeClassName="active"  onClick={runMenu}>ABOUT</NavLink>
                </li>
                <li id="link_active">
                    <NavLink to = "profile" activeClassName="active" id="prifileSwitch" onClick={runMenu}>{loginState}</NavLink>
                </li>
            </ul>
        </div>
    </div>
    
    );
}

//i thought u asked launch 
//lunch im gonna get somthing very
//very light
//sick ane
// ok 
