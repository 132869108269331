import "./Profile.scss";
import React, { useState, useEffect } from "react";
import dpfp from "/Users/calvinlloyd/Documents/chordgen-website/main-page/src/components/pages/default_pfp.jpg"
import {DB} from "/Users/calvinlloyd/Documents/chordgen-website/main-page/src/firebase-config.js"
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
function logoutfun(){
    window.localStorage.removeItem("LoginState")
    window.localStorage.removeItem("photoUrl")
    window.localStorage.removeItem("displayName")
    window.localStorage.removeItem("email")
    window.localStorage.setItem("navState","LOGIN/REGISTER")
    window.location.reload(false);
}

function run(){
    console.log("yes pfp");
}
function runo(){
    console.log("no pfp");
    const fileInput = document.getElementById('fileInput');
    const file = fileInput.files[0];
    if (file) {
        // Create a FormData object to store the file
        const formData = new FormData();
        formData.append('photo', file);
    
        // Send the FormData object to the server using fetch or XMLHttpRequest
        // Example using fetch:
        fetch('upload-url', {
          method: 'POST',
          body: formData
        })
        .then(response => {
          if (response.ok) {
            console.log('Photo uploaded successfully');
            // Optionally handle the response
          } else {
            console.error('Error uploading photo');
          }
        })
        .catch(error => {
          console.error('Error uploading photo:', error);
        });
      } else {
        console.error('No file selected');
      }
}
const getName = window.localStorage.getItem("displayName")
const getEmail = window.localStorage.getItem("email")
var pfp = window.localStorage.getItem("photoUrl")
if(pfp === null){
    pfp = dpfp;
    var check = false
}
else{
    check = true
}
async function getdata(getEmail){
    const element2 = document.getElementsByClassName('details-div')[0];
    const element3 = document.getElementsByClassName('loader')[0];
    element3.style.visibility = 'visible';
    element2.style.visibility = 'hidden';
    let count = 0;
    const usersCollectionRef = collection(DB,getEmail);
    const docSnap = await getDocs(usersCollectionRef);
    const documentIds = docSnap.docs.map(doc => doc.id);
    const temp_id = [];
    for (var i = 0; i < documentIds.length; i++){
        count = count +1;
        //console.log('Document IDs:', documentIds[i]);
        //temp_id.push(documentIds[i])
        const docRef = doc(DB, getEmail, documentIds[i]);
        const docSnap = await getDoc(docRef);
        const dat = docSnap.data()
        temp_id.push({id : dat['id'], key : dat['key'], chords : dat['chord progression'], link : dat['link']})
        //temp_id.push(dat['id']);
        // temp_id.push(dat['key']);
        // temp_id.push(dat['chord progression']);
        // temp_id.push(dat['link']);
    }
    element2.style.visibility = 'visible';
    element3.style.visibility = 'hidden';
    element3.style.display = 'none';
    return temp_id;

}



export const Profile = () => {
    const [userData, setUserData] = useState([])
    const [playLink, setPlayLink] = useState('')
    const [playId, setPlayId] = useState('none')
    const handleClick = (user) => {
        // Handle click event for the entire div or specific elements inside the div
        console.log('Clicked:', user);
    };
    useEffect(() => {
        async function pull(){
            const user = window.localStorage.getItem("email")
            const list_dat = await getdata(user)
            setUserData(list_dat)
        }
        pull();
    },[]);
    return (
        <div>
            <div className="loader_cont">
            {/* <div className="loader"></div> */}
            </div>
            <div className="main-container">
            <div className="profileContainer">
                <div className="user-box">
                    <div className="pfp" id="init-pfp" onClick={check? run : runo}>
                    <img alt="profile" src={pfp} width="96px" height="96px"></img>
                    </div>
                    <div className="sub-user-box">
                        <div>
                            {getName}
                        </div>
                        <div>
                            {getEmail}
                        </div>
                    </div>
                    <div className="log-but">
                    <button onClick={logoutfun}>LOGOUT</button>
                    </div>
                </div>
            </div>
            <div className="loader">
            </div>
            <div className="details-div">
                <div className="details-cont" id="detail-cont">
                    <div className="sub-details-div">
                        {userData.map((user) => (
                        <div  key={user.id} className="vp button_di" onClick={() => handleClick(user)}>
                            <div className="id_vp">
                            ID : {user.id.slice(1,6)}
                            </div>
                            <div>
                                KEY : {user.key}
                            </div>
                            <div>
                                PROGRESSION : {user.chords}
                            </div>
                            <a href={user.link} >DOWNLOAD</a>
                            <button onClick={(e) => {e.stopPropagation(); setPlayLink(user.link); setPlayId(user.id.slice(1,6)); if (window.innerWidth < 600) {window.scrollTo({ top: 0, behavior: 'smooth' })};}}>PLAY</button>
                        </div>
                        ))}
                    </div>
                </div>
                <div className="dummy-box">

                </div>
                <div className="visualiser2">
                    <div>
                        ID : {playId}
                    </div>
                    <div className="subout2">
                    <div className="player_vis">
                        <midi-visualizer type="piano-roll" id="mainVisualizer" src= {playLink}></midi-visualizer>
                        <midi-player src= {playLink} sound-font="" visualizer="#mainVisualizer" id="mainPlayer" data-js-focus-visible>
                        </midi-player>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
    
}
