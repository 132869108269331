import React from "react";
import calvin from './calvin.png';
import alvin from './alvin.png';
import "./About.css"
import mini_logo from './logo_only.png'
import bg from "/Users/calvinlloyd/Documents/chordgen-website/main-page/src/components/pages/bg_mod.png"

export const About = () => {
    return (
        <div className="mainn">
            <div className="cont1">
                <div className="item1">
                    <img src={calvin} alt="Logo" height="762px"/>  
                </div>
                <div className="item2">
                    <div className="title_div">
                    <pre>The CHORDGEN Project </pre>
                    <img src={mini_logo} alt="Logo" height="16px"/>
                    </div>
                    <p className="short">
                        using the LSTM RNN model to genrate new musical chords
                    </p>
                    <p>

                    </p>
                    <div className="block">
                        <p>
                        </p>
                    </div>
                    <img className="alvin" src={alvin} alt="Logo" height="500px"/> 
                </div>
            </div>
            <div className="cont2">
                <img src={bg} alt="alvin-calvin" height="370vh"/>
            </div>
        </div>
    );
    
}