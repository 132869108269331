import React, { useState } from 'react';
import { sendPasswordResetEmail } from "firebase/auth";
import {auth} from "/Users/calvinlloyd/Documents/chordgen-website/main-page/src/firebase-config.js"
import "./styles.css";

const sendEmail = async (email) => {
  await sendPasswordResetEmail(auth, email);
  const element = document.getElementsByClassName('message')[0];
  element.style.visibility = 'visible';
}


export const ForgotPass = () => {
  const [inputValue, setInputValue] = useState('');
  const handleChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmail(inputValue)
    // You can use the input value for further processing, such as sending it to an API or updating state.
  };
  return (
    <div>
        <div className='cont'>
            <div>
            <input
            id='em'
            type="email"
            placeholder="Email"
            name="email"
            required
            value={inputValue} onChange={handleChange}
            />
            </div>
            <button onClick={handleSubmit}>Reset</button>
            <p className='message'> 
                your reset link is sent to your email
            </p>
        </div>
    </div>
  );
}


// export default DinoGame;
