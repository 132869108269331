//import logo from './logo.svg';
import { Route, Routes } from 'react-router';
import './App.css';
import './components/NavBar'
import { NavBar } from './components/NavBar';
import {About} from './components/pages/About';
//import {Login} from './components/pages/Login.js';
import {Sgn} from './components/pages/Sgn.js';
import { Home } from './components/pages/Home';
import { ForgotPass } from '/Users/calvinlloyd/Documents/chordgen-website/main-page/src/components/pages/ForgotPass.js';
import image from './components/logo192.png';
import { DinoGame } from "/Users/calvinlloyd/Documents/chordgen-website/main-page/src/components/pages/DinoGame.js"
// import { Link } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import {Profile} from './components/pages/Profile.jsx';
function App() {
  // if(window.localStorage.getItem("LoginState") === null){
  //   window.localStorage.setItem("LoginState",false);
  // }
  const loginDetail = window.localStorage.getItem("LoginState")
  return (
    <div className="App">
      <NavBar></NavBar>
      <Routes>
        <Route path='/about' element ={<About></About>}/>
        <Route path='/profile' element ={loginDetail? <Profile></Profile> : <Sgn></Sgn>}/>
        <Route path='/' element = {<Home></Home>}/>
        <Route path='/game' element = {<DinoGame></DinoGame>}/>
        <Route path='/test' element = {<Profile></Profile>}/>
        <Route path='/forgotpassword' element = {<ForgotPass></ForgotPass>}/>
      </Routes>
      <div class = "footer_place">
        <div>
            TEAM ALVIN AND CALVIN
        </div>
        <div class = "footer_sub_div">
            <p>POWERD BY REACT</p>
            <NavLink to = "game" activeClassName="active"><img src={image} alt="Logo" height="30px"></img></NavLink>
        </div>
      </div>
    </div>
  );
}

export default App;
