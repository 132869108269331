import React from "react";
import "./styles.css";
import google from "./google.png"
import {auth, DB} from "/Users/calvinlloyd/Documents/chordgen-website/main-page/src/firebase-config.js"
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { collection, addDoc, doc, getDoc, setDoc, getDocs } from "firebase/firestore";
//import { collection, getDocs } from "firebase/firestore";
const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validatePassword = (password) => {
  // Regular expression for password validation (at least 8 characters, 1 lowercase letter, 1 digit)
  const passwordRegex = /^(?=.*\d)(?=.*[a-z]).{8,}$/;
  return passwordRegex.test(password);
};

const isNameNotEmpty = (name) => {
  return name.trim() !== '';
};

async function addDat(email,name,password){
  console.log(email)
  await setDoc(doc(DB, "chordgen", email), {
    "name":name,
    "email":email,
    "password":password
  });
}

function SignUpForm() {
  const [state, setState] = React.useState({
    name: "",
    email: "",
    password: ""
  });
  const handleChange = evt => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  };

  const handleOnSubmit = async evt => {
    evt.preventDefault();
    const { name, email, password } = state;
    console.log(validateEmail(email));
    if (validateEmail(email) && validatePassword(password) && isNameNotEmpty(name)){
      let flag = 0;
      const querySnapshot = await getDocs(collection(DB, "chordgen"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        if (doc.id !== email){
          flag = 0 + flag
        }
        else{
          flag = 1 + flag
        }
      });
      if (flag < 1){
        await addDat(email,name,password);
        window.localStorage.setItem("displayName", name);
        window.localStorage.setItem("email",email);
        window.localStorage.setItem("LoginState", true);
        window.localStorage.setItem("navState", "PROFILE");
        for (const key in state) {
          setState({
            ...state,
            [key]: ""
          });
        }
        window.location.reload();
      }
      else{
        alert(
          `email: ${email} already exist pls login`
        );
        for (const key in state) {
          setState({
            ...state,
            [key]: ""
          });
        }
      }
    }
    else{
      alert(
        `invalid name, email or password`
      );
    }
  };
  const handleGoogle = async (e) => {
    const provider = await new GoogleAuthProvider();
    const val = await signInWithPopup(auth, provider);
    console.log(val);
    window.localStorage.setItem("displayName", val._tokenResponse.displayName);
    window.localStorage.setItem("email", val._tokenResponse.email);
    window.localStorage.setItem("photoUrl", val._tokenResponse.photoUrl);
    window.localStorage.setItem("LoginState", true);
    window.localStorage.setItem("navState", "PROFILE");
    window.localStorage.setItem("Uid",val._tokenResponse.localId)
    window.location.reload()
}

  return (
    <div className="form-container sign-up-container">
      <form onSubmit={handleOnSubmit}>
        <h1>REGISTER</h1>
        <div className="social-container">
          <div className="social" onClick={handleGoogle}>
            <img className="fab fa-google-plus-g" alt="google" src={google} height="30px"/>
          </div>
        </div>
        <span>or use your email for registration</span>
        <input
          type="text"
          name="name"
          value={state.name}
          onChange={handleChange}
          placeholder="Name"
          required
        />
        <input
          type="email"
          name="email"
          value={state.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <input
          type="password"
          name="password"
          value={state.password}
          onChange={handleChange}
          placeholder="Password"
          required
        />
        <button onClick={handleOnSubmit}>REGISTER</button>
      </form>
    </div>
  );
}

export default SignUpForm;
