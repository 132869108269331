import React from "react";
import "./styles.css";
import google from "./google.png"
import {auth, DB} from "/Users/calvinlloyd/Documents/chordgen-website/main-page/src/firebase-config.js"
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { NavLink } from "react-router-dom";


// async function addDat(email,password){
//   await setDoc(doc(DB, "chordgen", email), {
//     "email":email,
//     "password":password
//   });
// }

async function checkData(email,password){
  const docRef = doc(DB, "chordgen", email);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const dat = docSnap.data();
    if(dat['password'] === password){
      window.localStorage.setItem("LoginState", true);
      window.localStorage.setItem("navState", "PROFILE");
      window.localStorage.setItem("email", dat["email"]);
      window.localStorage.setItem("displayName", dat["name"]);
      window.location.reload()
    }
    else{
      alert(
        `wrong password`
      );
    }
  } else {
    alert(
      `email: ${email} dosent exist pls register`
    );
  }
}

function SignInForm() {
  // const history = useHistory();
  // const handleClickk = () => {
  //   history.push('/profile');
  // };
  //const history = useNavigate();
  const [state, setState] = React.useState({
    email: "",
    password: ""
  });
  const handleChange = evt => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  };

  const handleOnSubmit = async evt => {
    evt.preventDefault();
    const { email, password } = state;
    //addDat(email,password);
    await checkData(email,password);

    for (const key in state) {
      setState({
        ...state,
        [key]: ""
      });
    }
  };
  const handleGoogle = async (e) => {
      const provider = await new GoogleAuthProvider();
      const val = await signInWithPopup(auth, provider);
      console.log(val);
      window.localStorage.setItem("displayName", val._tokenResponse.displayName);
      window.localStorage.setItem("email", val._tokenResponse.email);
      window.localStorage.setItem("photoUrl", val._tokenResponse.photoUrl);
      window.localStorage.setItem("LoginState", true);
      window.localStorage.setItem("navState", "PROFILE");
      window.localStorage.setItem("Uid",val._tokenResponse.localId)
      
      window.location.reload()
  }

  return (
    <div className="form-container sign-in-container">
      <form onSubmit={handleOnSubmit}>
        <h1>LOGIN</h1>
        <div className="social-container">
          <div onClick={handleGoogle} className="social">
            <img className="fab fa-google-plus-g" alt="google" src={google} height="30px"/>
          </div>
        </div>
        <span>or use your account</span>
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={state.email}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={state.password}
          onChange={handleChange}
          required
        />

        <NavLink to = "/forgotpassword" activeClassName="active">Forgot password ?</NavLink> 
        <button>LOGIN</button>
      </form>
    </div>
  );
}

export default SignInForm;
